section p {
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
}

div.title span {
    background-color: var(--cl-blue);
    position: absolute;
    height: 7px;
    width: 100%;
    bottom: 3px;
    opacity: 0.4;
    left: 0;
}

div.title {
    position: relative;
    display: inline-block;
    padding: 0 3px;
}

div.title h1 {
    font-size: 24px;
}

.signature {
    margin-top: 30px;
    text-align: right;
    font-size: 32px;
    font-family: 'Dancing Script', cursive;
}