div.timeline {
    max-width: 750px;
    width: 100%;
}

div.timeline h3:not(:first-child) {
    margin-top: 0;
}

div.timeline p {
    margin-top: 0;
}

div.timeline h5 { 
    font-weight: 500;
    margin-bottom: 15px;
}

div.timeline h5.time {
    font-weight: 700;
    color: var(--cl-d-white);
    margin: 5px 0;
}

