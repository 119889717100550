main.app {
    margin: 30px 20px;
}

main.app div.container {
    max-width: 750px;

    display: flex;
    flex-direction: column;
    gap: 30px;
}

header {
    background-color: var(--cl-grey);

    height: 75px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    padding: 0 20px;
    border-radius: 10px;

    width: 100vw;
    max-width: 710px;
}

header nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    list-style: none;
}

header nav ul li a {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 700;

    width: max-content;
    position: relative;
}

header nav ul li a::before {
    content: "";
    position: absolute;
    bottom: -3px;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: var(--cl-blue);
    transition: 0.3s ease-in-out;
    transform: scaleX(0);
}

header nav ul li a:hover::before {
    transform: scaleX(1);
}

a.active {
    color: var(--cl-blue);
}

section {
    width: 100vw;
    max-width: 710px;

    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    background-color: var(--cl-grey);

    padding: 20px;
    border-radius: 10px;
}

button.lang {
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    transition: ease-in-out 0.2s;
}

button.lang:hover {
    cursor: pointer;
    transform: scale(1.1);
}


footer {
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    background-color: var(--cl-grey);

    height: 75px;

    padding: 0 20px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 710px;
}

nav.for-mobile {
    display: none;
}

.no-mobile {
    display: block;
}

.desktop-nav {
    display: flex;
}

@media screen and (max-width: 475px) {
    section {
        max-width: calc(100vw - 80px);
    }

    .no-mobile {
        display: none;
    }

    .desktop-nav {
        display: none;
    }

    footer {
        justify-content: center;
    }

    nav.for-mobile {
        display: block;
    }
}






svg.rotate {
    transform: rotate(180deg);
}

nav.for-mobile button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--cl-grey);


    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    overflow: hidden;
    padding: 15px 20px;
    text-align: center;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

nav.for-mobile ul {
    background-color: var(--cl-grey);
    border-radius: 10px;
    list-style: none;
}

nav.for-mobile ul li {
    animation: display-item 1s ease-in-out forwards;
}

nav.for-mobile ul li a {
    padding: 15px 20px;
    display: flex;

    font-size: 16px;
    font-weight: 700;
}

nav.for-mobile ul li:not(:last-child) {
    border-bottom: 1px solid var(--cl-white);
}

nav.for-mobile ul.open {
    margin-top: 10px;
    /* animation: display-nav 0.5s ease-in-out; */
}

nav.for-mobile ul.open li {
    opacity: 0;
}

nav.for-mobile ul.close {
    display: none;
}

nav.for-mobile ul li button {
    box-shadow: none;
    background-color: transparent;

    text-align: start;
    padding: 15px 20px;

    width: 100%;

    outline: none;
    border: none;
}

nav.for-mobile ul li:nth-child(1) {
    animation-delay: 0s;
  }
  
  nav.for-mobile ul  li:nth-child(2) {
    animation-delay: 0.25s;
  }
  
  nav.for-mobile ul  li:nth-child(3) {
    animation-delay: 0.5s;
  }
  
  nav.for-mobile ul  li:nth-child(4) {
    animation-delay: 0.75s;
  }

  nav.for-mobile ul  li:nth-child(5) {
    animation-delay: 1s;
  }

@keyframes display-item {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}





@media screen and (min-width: 475px) {
    main.app {
        margin: 30px 12vw;
        display: flex;
        justify-content: center;
        gap: 30px;
    }
}