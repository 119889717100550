@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

:root {
  --cl-dark: #333331;
  --cl-grey: #434343;
  --cl-l-grey: #70706F; 
  --cl-d-white: #c7c7c7;
  --cl-blue: #1c73d1;
  --cl-white: #EFEFFA;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--cl-dark);

  background-image: url("./img/Bc.svg");
  background-size: contain;
  background-position: center top;
  background-repeat: repeat;

  color: var(--cl-white);
}



a {
  color: var(--cl-white);
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

/* CSS */
.btn {
  background-color: var(--cl-blue);
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  min-height: 44px;
  min-width: 10px;
  outline: none;
  overflow: hidden;
  padding: 9px 20px 8px;
  text-align: center;
  width: 100%;
}

.btn:hover,
.btn:focus {
  opacity: .75;
}



::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--cl-grey);
}
 
::-webkit-scrollbar-thumb {
  background: var(--cl-l-grey); 
  border-radius: 4px;
}



.move-text {
  animation: move-from-right 2s forwards;
  animation-delay: 0.3s;
}

@keyframes move-from-right {
  0% {
    opacity: 0.3;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.display-text {
  animation: display-text 0.75s forwards;
  animation-delay: 0.3s;
  opacity: 0;
}

@keyframes display-text {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
