main.full-size img {
    background-color: var(--cl-white);
    border-radius: 50%;
    width: 50vw;
    max-width: 300px;
    height: auto;
}

main.full-size h1 {
    font-size: 24px;
    padding: 0 20px;
}

main.full-size h2 {
    font-weight: 500;
}

main.full-size a {
    background-color: var(--cl-blue);
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    padding: 9px 20px 8px;
    text-align: center;
}