div.grid-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
}

div.tab {
    margin-top: 20px;
    text-align: center;
}

div.tab img {
    width: 70px;
    height: 70px;
    /* border-radius: 10px; */
    border-radius: 17px;
    background-color: var(--cl-white);
    padding: 3px;
    transition: ease-in-out 0.25s;
}

div.tab img:hover {
    transform: scale(1.1);
}

h3 {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

div.tab h5 {
    font-weight: 600;
}

div.tab h5:last-child {
    color: var(--cl-d-white);
}