aside {
    margin-bottom: 30px;
    background-color: var(--cl-grey);

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    
    border-radius: 10px;
    padding-top: 15px;
    width: 100%;
}

aside div.my-pic {
    background-image: url("../../img/Me.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    height: 490px;
    border-radius: 10px;
    margin: 0 15px 15px 15px;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

aside div.my-pic::before {
    content: "";
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
}

aside div.my-pic h1 {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 700;
    z-index: 10;
}

aside div.my-pic h2 {
    font-size: 13px;
    color: var(--cl-d-white);
    z-index: 10;
    margin-bottom: 30px;
}





aside ul {
    list-style: none;
    margin: 15px;
}

aside ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

aside ul li h4 {
    font-weight: 600;
    font-size: 15px;
}

aside ul li h4:last-child {
    color: var(--cl-d-white);
    font-weight: 500;
}





div.center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--cl-white);
}

a.download {
    padding: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    width: 100%;
    color: var(--cl-d-white);

    font-weight: 700;

    transition: ease-in-out 0.15s;
}

a.download > svg {
    transition: ease-in-out 0.15s;
}

a.download:hover, a.download:hover > svg {
    fill: var(--cl-blue);
    color: var(--cl-blue);
}




@media screen and (min-width: 475px) {
    aside {
        position: sticky;
        top: 30px;
        width: 300px;
        height: max-content;
    }

    aside div.my-pic {
        height: 370px;
    }
}

.display-anim {
    animation: display-animation 0.75s ease-in-out;
}

@keyframes display-animation {
    0% {
        transform: scale(0.2);
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}