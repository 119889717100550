div .cont-container {
    display: flex;
    gap: 50px;
}

div.cont-container > div {
    flex: 1;
}

div.cont-container div {
    flex: 1;
}

div.cont-container h3 {
    font-weight: 700;
}




form.ct-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

form.ct-form label h4 {
    font-weight: 500;
    margin: 10px 0;
}

form.ct-form input[type="text"], form.ct-form input[type="email"], form.ct-form textarea {
    font-family: 'Open Sans', sans-serif;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 12px;
    background: transparent;
    width: calc(100% - 28px);
    height: 36px;
    border: 2px solid var(--cl-white);
    outline: none;
    border-radius: 5px;
    transition: 0.25s;
    color: var(--cl-white);
}

form.ct-form input[type="text"]:focus, form.ct-form input[type="email"]:focus, form.ct-form textarea:focus {
    border-color: var(--cl-blue);
}

form.ct-form textarea {
    resize: vertical;
    min-height: 150px;
    max-height: 500px;
    height: 150px;
}



div.info h3 {
    border-bottom: 2px solid var(--cl-white);
    margin-top: 25px;
    padding-bottom: 10px;
}

div.info ul {
    margin-top: 15px;
}

div.info ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

div.info ul li h4 {
    font-weight: 600;
}

div.info ul li a:hover {
    color: var(--cl-blue);
}




div.socials {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

div.socials a svg {
    fill: var(--cl-white);
}

div.socials a, div.socials a svg {
    transition: 0.2s ease-in-out;
}

div.socials a:hover {
    transform: scale(1.2);
}

div.socials a:hover svg {
    fill: var(--cl-blue);
}



@media screen and (max-width: 475px) {
    div.cont-container {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
}